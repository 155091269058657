<template>
  <v-card tile v-if="case_frm">
    <v-toolbar color="#083272" dark class="">
      <v-toolbar-title>
        <h3 class="font-weight-bold suptitle">Preview</h3>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="scroll">
      <v-container class="py-6">
        <v-row>
          <v-col cols="12"><h1>Product Information</h1></v-col>
        </v-row>
        <v-divider class="mt-3 mb-3"></v-divider>
        <v-row>
          <v-col cols="12">
            <p class="text-h6 font-weight-bold py-0 my-0">고객사</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_company_name }}
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <p class="text-h6 font-weight-bold py-0 my-0">계약</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.contract_name }}
            </p>
          </v-col>
          <v-col cols="12" md="6" v-if="case_frm.case_type != 'Administrative'">
            <p class="text-h6 font-weight-bold py-0 my-0">솔루션(제품)</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_product_name }}
            </p>
          </v-col>
          <v-col cols="12" md="6" v-if="case_frm.case_type != 'Administrative'">
            <p class="text-h6 font-weight-bold py-0 my-0">Client FullName</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_client_user_name }}
            </p>
          </v-col>
          <v-col cols="12" md="6" v-if="case_frm.case_type != 'Administrative'">
            <p class="text-h6 font-weight-bold py-0 my-0">Client E-mail</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_client_user_mail }}
            </p>
          </v-col>
          <v-col cols="12" md="6" v-if="case_frm.case_type != 'Administrative'">
            <p class="text-h6 font-weight-bold py-0 my-0">Client Phone</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_client_user_phone }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              case_frm.case_type == 'Technical' &&
              case_frm.case_product_type_cloud
            "
          >
            <p class="text-h6 font-weight-bold py-0 my-0">Org ID</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_product_org_id }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              case_frm.case_type == 'Technical' &&
              case_frm.case_product_type_cloud
            "
          >
            <p class="text-h6 font-weight-bold py-0 my-0">Secure Agent</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_product_secure_agent }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              case_frm.case_type == 'Technical' &&
              case_frm.case_product_type_etc
            "
          >
            <p class="text-h6 font-weight-bold py-0 my-0">Activity Type</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_product_activity_type }}
            </p>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              case_frm.case_type == 'Technical' &&
              case_frm.case_product_type_etc
            "
          >
            <p class="text-h6 font-weight-bold py-0 my-0">Environment Type</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_product_environment_type }}
            </p>
          </v-col>
          <v-col cols="12" v-if="case_frm.case_type == 'Fulfillment'">
            <p class="text-h6 font-weight-bold py-0 my-0">Environment Type</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_product_environment_type }}
            </p>
          </v-col>
          <v-col cols="12" md="6" v-if="case_frm.case_type != 'Administrative'">
            <p class="text-h6 font-weight-bold py-0 my-0">버전</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_product_version }}
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <p class="text-h6 font-weight-bold py-0 my-0">중요도</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_priority_name }}
            </p>
          </v-col>
          <v-col cols="12" v-if="case_frm.case_type == 'Administrative'">
            <p class="text-h6 font-weight-bold py-0 my-0">Problem Area</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_problem_area }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12 mt-4"><h1>Case Information</h1></v-col>
        </v-row>
        <v-divider class="mt-3 mb-3"></v-divider>
        <v-row>
          <v-col cols="12">
            <p class="text-h6 font-weight-bold py-0 my-0">제목</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              {{ case_frm.case_subject }}
            </p>
          </v-col>

          <v-col cols="12">
            <p class="text-h6 font-weight-bold py-0 my-0">내용</p>
            <div
              class="pa-1"
              style="border: solid 1px #000"
              v-html="case_frm.case_description"
            ></div>
          </v-col>
          <v-col cols="12" v-if="case_frm.case_type == 'Technical'">
            <p class="text-h6 font-weight-bold py-0 my-0">에러 로그</p>
            <div
              class="pa-1"
              style="border: solid 1px #000"
              v-html="case_frm.case_errorlog"
            ></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="text-h6 font-weight-bold py-0 my-0">Attachments</p>
            <p class="text-subtitle-1 pa-1" style="border: solid 1px #000">
              <v-data-table
                dense
                :headers="attachmentHeaders"
                :items="case_frm.attachments"
                class="elevation-0"
                hide-default-footer
              >
              </v-data-table>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <v-btn
              color="#FF4D00"
              class="ml-auto"
              dark
              outlined
              @click="on_close"
            >
              BACK
              <v-icon small class="ml-2"> mdi-close </v-icon>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              color="success"
              dark
              block
              @click="on_proceed"
              ref="createBtn"
            >
              Create Case <v-icon small class="ml-2"> mdi-check </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    case_frm: {
      type: Object,
      require: true,
    },
    on_close: {
      type: Function,
      require: false,
    },
    on_proceed: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      attachmentHeaders: [
        { text: "Name", value: "file_name" },
        { text: "Size", value: "file_size" },
        { text: "Mimetype", value: "file_mimetype" },
      ],
    };
  },
};
</script>

<style>
.scroll {
  /* overflow-y: scroll; */
}
.fixed-top {
  z-index: 999;
}
</style>
