<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="pt-0 px-0">
            <v-toolbar color="#083272" dark>
              <v-toolbar-title>
                <h3 class="font-weight-bold text-center">
                  Create New Technical Case
                </h3>
              </v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <h2>Product Information</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-if="selectedCompany"
                    v-model="selectedCompany.company_name"
                    outlined
                    light
                    dense
                    hide-details
                    label="Company"
                    class="px-1 pt-0"
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-if="selectedProject"
                    v-model="selectedProject.contract_name"
                    outlined
                    light
                    dense
                    hide-details
                    label="Project"
                    class="px-1 pt-0"
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="selectedProduct"
                    outlined
                    light
                    dense
                    hide-details
                    label="Product"
                    :items="productList"
                    class="px-1 pt-0"
                  ></v-select>
                  <span
                    class="py-0 my-0 error--text font-weight-bold"
                    v-if="productIsExpired"
                  >
                    * 이 제품은 사용기간이 Expired 되었습니다. <br />
                    * ISK GCS 담당자에게 문의 바랍니다.
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    selectedProduct != null &&
                    selectedProduct.product_type_cloud == true
                  "
                >
                  <v-container fluid class="pa-0">
                    <v-row>
                      <v-col col="6">
                        <v-text-field
                          v-model="case_frm.case_product_org_id"
                          outlined
                          light
                          dense
                          hide-details
                          label="Org ID"
                          class="px-1 pt-0"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col col="6">
                        <v-text-field
                          v-model="case_frm.case_product_secure_agent"
                          outlined
                          light
                          dense
                          hide-details
                          label="Secure Agent"
                          class="px-1 pt-0"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    selectedProduct != null &&
                    selectedProduct.product_type_etc == true
                  "
                >
                  <v-container fluid class="pa-0">
                    <v-row>
                      <v-col col="6">
                        <v-select
                          :items="activityTypeList"
                          v-model="case_frm.case_product_activity_type"
                          outlined
                          light
                          dense
                          hide-details
                          label="Activity Type"
                          class="px-1 pt-0"
                        >
                        </v-select>
                      </v-col>
                      <v-col col="6">
                        <v-select
                          :items="environmentTypeList"
                          v-model="case_frm.case_product_environment_type"
                          outlined
                          light
                          dense
                          hide-details
                          label="Environment Type"
                          class="px-1 pt-0"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="version_edit"
                    :label="`Add New Version`"
                    hide-details
                    class="py-0 pl-1 my-0 mb-1"
                    @change="case_frm.case_product_version = null"
                  />
                  <v-text-field
                    v-if="version_edit"
                    v-model="case_frm.case_product_version"
                    outlined
                    light
                    dense
                    hide-details
                    label="Version"
                    class="px-1 py-0"
                  >
                  </v-text-field>
                  <v-select
                    v-if="!version_edit"
                    :items="versionList"
                    v-model="case_frm.case_product_version"
                    outlined
                    light
                    dense
                    hide-details
                    label="Version"
                    class="px-1 py-0"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="selectedPriority"
                    outlined
                    light
                    dense
                    hide-details
                    label="Priority"
                    :items="priorityList"
                    class="px-1 pt-0"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Client Information</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0 my-0 error--text font-weight-bold">
                  <span>
                    *신설 : 고객사 담당자 성명과 이메일, 전화번호를
                    기재하십시오.
                  </span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    v-model="case_frm.case_client_user_name"
                    label="Client Fullname"
                    class="px-1 pt-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    v-model="case_frm.case_client_user_mail"
                    label="Client E-mail"
                    class="px-1 pt-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    v-model="case_frm.case_client_user_phone"
                    label="Client Phone"
                    class="px-1 pt-0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Case Information</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    v-model="case_frm.case_subject"
                    @input="changeSubject"
                    label="Subject"
                    class="px-1 pt-0"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <h4>Description</h4>
                  <span class="py-0 my-0 error--text font-weight-bold">
                    * 파트너사에서는 1차 분석 내용을 포함시켜 주시기 바랍니다.
                  </span>
                  <tiptap-vuetify
                    v-model="case_frm.case_description"
                    :extensions="extensions"
                    placeholder="문제에 대한 자세한 설명을 기입해 주시기 바랍니다."
                    :disableInputRules="true"
                    :disablePasteRules="true"
                  />
                </v-col>
                <v-col cols="12">
                  <h4>Error Log</h4>
                  <tiptap-vuetify
                    v-model="case_frm.case_errorlog"
                    :extensions="extensions"
                    placeholder="에러 메시지를 복사후 이곳에 붙여 넣으시기 바랍니다."
                    :disableInputRules="true"
                    :disablePasteRules="true"
                  />
                </v-col>
                <v-col cols="12">
                  <Attachments v-model="case_frm.attachments" />
                </v-col>

                <v-col cols="auto" class="ml-auto">
                  <v-btn block color="#FF4D00" dark @click="proceedCase">
                    PROCEED &nbsp;
                    <v-icon> mdi-arrow-right </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title class="pt-0 px-0">
            <v-toolbar color="#083272" dark>
              <v-toolbar-title>
                <h3 class="font-weight-bold text-center">Knowledge base</h3>
              </v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-data-table
              height="600"
              :items="KBList"
              :headers="KBheaders"
              @click:row="postClick"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="previewModel" max-width="1000px" ref="previews">
      <Preview
        v-if="previewModel"
        :case_frm="case_frm"
        :on_proceed="createCase"
        :on_close="modalBackBtn"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Preview from "@/components/Views/NewCase/Preview";
import Attachments from "@/components/Views/NewCase/Attachments";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "@jwmsg/tiptap-vuetify";
import { getProductByContract } from "@/api/Product";
import { getPriority } from "@/api/Presets";
import { newCase } from "@/api/Case";
import moment from "moment";
import { getPostsKB } from "@/api/Post";
export default {
  components: { TiptapVuetify, Preview, Attachments },
  beforeMount() {
    if (this.selectedCompany == null || this.selectedProject == null) {
      alert("잘못된 접근입니다. 정상적인 방법으로 접근해 주시기 바랍니다.");
      this.$router.push("/");
    }

    this.autoSelect();

    this.case_frm.case_description = `
    <h2><strong>1. 이슈 발생 시간</strong></h2>
    <p>&lt;이슈 발생 시점 또는 시간대&gt; <em>예시: 2024-06-19 14:30</em></p>
    <hr contenteditable="false">
    <h2><strong>2. 시스템 정보</strong></h2>
    <p>&lt;Informatica 제품이 설치된 시스템 종류 및 OS 버전&gt; <em>예시: AIX 7.1 또는 WindowServer Standard 2014 등</em></p>
    <hr contenteditable="false">
    <h2><strong>3. 소스/타겟 애플리케이션 정보</strong></h2>
    <p><em>예시: 소스: Oracle 19c / 타겟: MSSQL Server 20XX</em></p>
    <hr contenteditable="false">
    <h2><strong>4. 이슈 상황 설명</strong></h2>
    <p>&lt;이슈 상황을 구체적이고 자세히 설명&gt;</p>
    <p><em>예시: 데이터 Insert 중 오류 발생, 오류 메시지: "Connection timeout"</em></p>
    <hr contenteditable="false">
    <h2>5. 특별한 체크 포인트</h2><p>&lt;이슈에 대한 히스토리 및 분석 시 별도로 참고해야 하는 사항&gt;</p>
    <p>예시: 과거 유사한 이슈 발생 시 해결 방법, 현재 적용된 패치 내역</p>
    <hr contenteditable="false">
    <h2>6. 주의사항</h2>
    <p> <u>이 가이드라인 템플릿은 최적화된 분석 지원을 위해 중요합니다.</u> </p>
    <p><u>템플릿을 무시하고 입력할 경우에도 Case 생성은 가능하지만, <strong>지속적으로 템플릿을 무시할 경우 Case Open이 반려</strong> 될 수 있습니다.</u></p>
    `;
  },
  computed: {
    selectedCompany() {
      return this.$store.getters["mainDash/selectedCompany"];
    },
    selectedProject() {
      return this.$store.getters["mainDash/selectedContract"];
    },
    productIsExpired() {
      if (this.selectedProduct == null) return false;
      const current = moment();
      const expried = moment(this.selectedProduct.contr_prod_end_date);
      if (expried.diff(current, "d").toString() < 0) {
        return true;
      }
      return false;
    },
  },
  watch: {
    selectedProduct() {
      this.getProductVersionByContract();
    },
  },
  methods: {
    clearVersion() {
      this.case_frm.case_product_version == null;
    },
    changeSubject(str) {
      const total_str = str;
      if (this.searchEvtTimerIdx != null) {
        clearTimeout(this.searchEvtTimerIdx);
        this.searchEvtTimerIdx = null;
      }
      this.searchEvtTimerIdx = setTimeout(() => {
        this.searchKB(total_str);
      }, 1000);
    },
    async searchKB(query) {
      const data = await getPostsKB({
        post_type: "KnowledgeBase",
        post_title: query,
        post_content: query,
        post_tag: query,
      });
      if (data.success) {
        this.KBList = data.data;
      }
    },
    async autoSelect() {
      await this.getPriority();
      await this.getMyProduct();
      this.selectedProduct = this.productList[0].value;
      this.selectedPriority = this.priorityList[2].value;
    },
    async getMyProduct() {
      if (this.selectedProject == null) return;
      const data = await getProductByContract(
        this.selectedProject.contract_uuid
      );
      if (data.success) {
        console.log(data.data);
        const list = data.data.map((e) => {
          e.TB_INFO_PRODUCT_LIST.contr_prod_end_date = e.contr_prod_end_date;
          return {
            text: e.TB_INFO_PRODUCT_LIST.product_name,
            value: e.TB_INFO_PRODUCT_LIST,
          };
        });
        this.productList = list;
      }
    },
    async getPriority() {
      const data = await getPriority();
      if (data.success) {
        const list = data.data.map((e) => ({
          text: e.level_name,
          value: e,
        }));
        this.priorityList = list;
      }
    },
    async getProductVersionByContract() {
      if (this.selectedProject == null || this.selectedProduct == null) return;
      this.versionList = this.selectedProduct.TB_JOIN_PRODUCT_VERSIONs.map(
        (e) => ({ text: e.product_version })
      );
    },
    inputChecker() {
      if (this.selectedProject == null) {
        alert("회사를 선택하십시오.");
        return false;
      }
      if (this.selectedProject == null) {
        alert("프로젝트(계약)를 선택하십시오.");
        return false;
      }
      if (this.selectedProduct == null) {
        alert("제품을 선택하십시오.");
        return false;
      }
      if (
        this.case_frm.case_client_user_name == null ||
        this.case_frm.case_client_user_name.trim() == ""
      ) {
        alert("고객사 담당자 성명을 기재하십시오.");
        return false;
      }
      if (
        this.case_frm.case_client_user_mail == null ||
        this.case_frm.case_client_user_mail.trim() == ""
      ) {
        alert("고객사 담당자 이메일을 기재하십시오.");
        return false;
      }
      if (this.case_frm.case_client_user_phone == null) {
        alert("고객사 담당자 전화번호를 확인하십시오.");
        return false;
      }
      const phoneCheck = /^\d{7,}$/;
      this.case_frm.case_client_user_phone =
        this.case_frm.case_client_user_phone.trim();
      if (!phoneCheck.test(this.case_frm.case_client_user_phone)) {
        alert("고객사 담당자 전화번호를 확인하십시오. (숫자만 입력)");
        return false;
      }
      if (this.selectedProduct != null) {
        if (this.selectedProduct.product_type_cloud) {
          if (
            this.case_frm.case_product_org_id == null ||
            this.case_frm.case_product_org_id == ""
          ) {
            alert("Org ID 를 입력하십시오");
            return false;
          }
          if (
            this.case_frm.case_product_secure_agent == null ||
            this.case_frm.case_product_secure_agent == ""
          ) {
            alert("Secure Agent 를 입력하십시오");
            return false;
          }
        }
        if (this.selectedProduct.product_type_etc) {
          if (
            this.case_frm.case_product_activity_type == null ||
            this.case_frm.case_product_activity_type == ""
          ) {
            alert("Activity Type 을 입력하십시오");
            return false;
          }
          if (
            this.case_frm.case_product_environment_type == null ||
            this.case_frm.case_product_environment_type == ""
          ) {
            alert("Environment Type 을 입력하십시오");
            return false;
          }
        }
      }
      if (
        this.case_frm.case_product_version == null ||
        this.case_frm.case_product_version == ""
      ) {
        alert("버전을 선택(입력)하십시오.");
        return false;
      }
      if (
        this.case_frm.case_subject == null ||
        this.case_frm.case_subject == ""
      ) {
        alert("제목을 입력하십시오.");
        return false;
      }
      if (
        this.case_frm.case_description == null ||
        this.case_frm.case_description == ""
      ) {
        alert("설명을 입력하십시오.");
        return false;
      }
      return true;
    },
    async proceedCase() {
      if (!this.inputChecker()) return;
      this.case_frm.case_company_name = this.selectedCompany.company_name;
      this.case_frm.case_company_uuid = this.selectedCompany.company_uuid;
      this.case_frm.contract_uuid = this.selectedProject.contract_uuid;
      this.case_frm.contract_name = this.selectedProject.contract_name;
      this.case_frm.case_product_uuid = this.selectedProduct.product_uuid;
      this.case_frm.case_product_name = this.selectedProduct.product_name;
      this.case_frm.case_product_type_cloud =
        this.selectedProduct.product_type_cloud;
      this.case_frm.case_product_type_etc =
        this.selectedProduct.product_type_etc;
      this.case_frm.case_priority = this.selectedPriority.priority_level;
      this.case_frm.case_priority_name = this.selectedPriority.level_name;
      this.previewModel = true;
    },
    async modalBackBtn() {
      this.previewModel = false;
    },
    async createCase() {
      if (this.isProceed == true) {
        return alert("진행중입니다. 잠시만 기다려 주세요..");
      }
      this.isProceed = true;
      const response = await newCase(this.case_frm);
      console.log(response);
      if (response.success) {
        this.$router.push(`/case/${response.data.case_uuid}`);
        this.previewModel = false;
      } else {
        this.isProceed = false;
        alert(
          `케이스 등록에 문제가 발생하였습니다.\n${JSON.stringify(
            response.message
          )}`
        );
      }
    },
    postClick(post) {
      console.log(window.location.origin);
      window.open(
        `${window.location.origin}/posts/view/${post.post_id}`,
        "_blank"
      );
    },
  },
  data() {
    return {
      searchEvtTimerIdx: null,
      productList: [],
      priorityList: [],
      versionList: [],
      KBList: [],
      KBheaders: [{ text: "Title", align: "start", value: "post_title" }],
      activityTypeList: [
        "Report service outage",
        "Report an issue for any queries",
        "Request an activity/change request",
      ],
      environmentTypeList: ["DEV", "QA", "PROD", "SUP", "SIT", "UAT"],
      selectedProduct: null,
      selectedPriority: null,
      version_edit: false,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      description: "",
      errorlog: "",
      case_frm: {
        case_company_uuid: null,
        contract_uuid: null,
        case_product_uuid: null,
        case_product_version: null,
        case_priority: null,
        case_type: "Technical",
        case_subject: null,
        case_description: "",
        case_errorlog: null,
        case_client_user_name: null,
        case_client_user_mail: null,
        case_client_user_phone: null,
        attachments: [],
      },
      selectedFile: null,
      previewModel: false,
      isProceed: false,
    };
  },
};
</script>

<style>
.tiptap-vuetify-editor .ProseMirror {
  min-height: 250px;
  max-height: 250px;
}
</style>
